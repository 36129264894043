import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { trackGAEvent } from '@/utility/analytics';
import config from '@/utility/config';
import { t } from '@/utility/localization';
import {
  FORGET_PASSWORD_ROUTE,
  LOGIN_ROUTE
} from '@/utility/routesHelper';

import { useAuthContext } from '@/contexts/AuthContext';

import FloatingLabelInput from '@/components/common/Form/FloatingLabelInput';
import RoundedButton from '@/components/common/RoundedButton';

const PasswordForm = ({ formValues, setFormState, postLogin }) => {
  const [passwordError, setPasswordError] = useState();
  const [password, setPassword] = useState();
  const { loginError, isLoading, login } = useAuthContext();
  const pathname = usePathname();

  const { siteBaseUrl } = config;

  const onLogin = async (e) => {
    e.preventDefault();

    const { email } = formValues;

    const callback = (response) => {
      if (response?.error) {
        setPasswordError(response?.error);
        return;
      }

      postLogin?.(response?.data.user);

      trackGAEvent('login_with_password_success');
    };
    const isInLoginPage = pathname === LOGIN_ROUTE;
    await login(email, password, callback, isInLoginPage);
  };

  const onFieldChange = (e) => {
    setPasswordError('');
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (!password) {
      setPasswordError('');
    }
  }, [password]);

  useEffect(() => {
    if (password) {
      if (loginError) {
        setPasswordError(loginError);
      }
    } else {
      setPasswordError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginError]);

  return (
    <div className="c-PasswordForm">
      <form onSubmit={onLogin}>
        <div className="mb-16">
          <FloatingLabelInput
            type="email"
            name="email"
            placeholder={t('email')}
            label={t('email')}
            floatingLabel={t('email')}
            value={formValues?.email}
            disabled
          />
        </div>
        <div className="my-16 space-y-16">
          <div>
            <FloatingLabelInput
              type="password"
              value={password}
              name="password"
              onChange={onFieldChange}
              label={t('password')}
              floatingLabel={t('password')}
              placeholder={t('password')}
              error={passwordError}
              required
              focus
              data-testid="-input-password"
            />
          </div>
          <a
            className="block text-right text-label-sm font-medium text-npl-text-icon-on-light-surface-secondary"
            title={t('forgot-password')}
            href={`${siteBaseUrl}${FORGET_PASSWORD_ROUTE}`}
            rel="noreferrer"
            target="_blank"
            data-testid="forgot-password">
            {t('forgot-your-password')}
          </a>
        </div>
        <div className="space-y-8">
          <RoundedButton
            displayType="primary"
            type="submit"
            disabled={!password}
            isLoading={isLoading}
            data-testid="login-button"
            customClassNames="w-full h-[48px]">
            {t('continue')}
          </RoundedButton>
          <RoundedButton
            displayType="plain"
            customClassNames="w-full flex justify-center h-[48px] text-npl-text-icon-on-light-surface-secondary"
            data-test-id="back-button"
            disabled={isLoading}
            onClick={() => {
              setFormState((prev) => ({
                current: prev.previous
              }));
            }}>
            {t('back')}
          </RoundedButton>
          <p className="text-label-sm text-npl-text-icon-on-light-surface-tertiary">
            {t('by-continuing-you-are-agreein')}
            <a
              className="ml-4 underline"
              href="https://nas.io/community-manager-terms">
              {t('terms-and-conditions')}
            </a>
          </p>
        </div>
      </form>
    </div>
  );
};

export default PasswordForm;
