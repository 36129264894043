import style from './SocialLogins.module.scss';

import classNames from 'classnames';

import { t } from '@/utility/localization';

import { useGoogleLoginContext } from '@/contexts/GoogleLoginProvider';

import NPLButton from '@/components/npl/NPLButton';

import { NO_COMMUNITY_ERROR } from '@/pages/login/constants';

const SocialLogins = ({
  isLoading,
  handleSocialAuth,
  socialLoginError,
  customClassNames = '',
  isCommunityAdmin = true,
  shouldRedirect = true,
  disabled = false
}) => {
  const className = 'c-SocialLogins';
  const baseClassName = classNames(className, style[className]);

  const socialBtnClass = `${className}__social-btn-wrapper`;
  const googleBtnClass = classNames(
    `${socialBtnClass}--google`,
    style[socialBtnClass],
    style[`${socialBtnClass}--google`]
  );

  const { useGoogleLogin, isReady } = useGoogleLoginContext();

  const googleOnClick = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (res) => {
      handleSocialAuth(res, 'google', isCommunityAdmin, shouldRedirect);
    },
    onError: (res) => {
      handleSocialAuth(res, 'google', isCommunityAdmin, shouldRedirect);
    }
  });
  return (
    <div className={classNames(baseClassName, customClassNames)}>
      {socialLoginError && socialLoginError !== NO_COMMUNITY_ERROR && (
        <p className={style[`${className}__err-msg`]}>
          {socialLoginError}
        </p>
      )}

      {/* Google Login Button */}

      <div className={googleBtnClass}>
        <NPLButton
          disabled={disabled}
          stretch
          size="lg"
          hierarchy="outline"
          loading={isLoading || !isReady}
          leadIcon="google-coloured"
          leadIconType="social"
          buttonText={t('continue-with-google')}
          data-test-id="google-login-btn"
          onClick={() => googleOnClick()}
        />
      </div>
    </div>
  );
};

export default SocialLogins;
